// Add your custom JS here.
  // Wait for the page to finish loading

// Custom JS for single product pages to hide section if there are no related products
function hideRelatedSectionOnSingleProduct() {
  // Check if the subsequent section's inner div is empty
  var subsequentSection = document.getElementById("arawoo-related-check");
  var widgetWrap = subsequentSection.querySelector(".elementor-widget-wrap.elementor-element-populated");
  
  if (widgetWrap.innerHTML.trim() === "") {
    // If the inner div is empty, hide the target section
    var targetSection = document.getElementById("arawoo-related-title");
    targetSection.style.display = "none";
  }
}

// Check if the current page is a single product page before executing the code
if (document.body.classList.contains("single-product")) {
  document.addEventListener("DOMContentLoaded", hideRelatedSectionOnSingleProduct);
}


// Custom JS for cart pages to hide section if there are no related products
function hideRelatedSectionOnCart() {
  // Check if the subsequent section's inner div is empty
  var subsequentSection = document.getElementById("arawoo-related-check-cart");
  var widgetWrap = subsequentSection.querySelector(".cross-sells");
  
  if (!widgetWrap) {
    // If the inner div is empty, hide the target section
    var targetSection = document.getElementById("arawoo-related-title-cart");
    targetSection.style.display = "none";
  }
}

// Check if the current page is the cart page before executing the code
if (window.location.href.indexOf("cart") > -1) {
  document.addEventListener("DOMContentLoaded", hideRelatedSectionOnCart);
}

//Mobile Menu phone number handling
jQuery(document).ready(function($) {
  $('ul#mega-menu-primary').on('mmm:showMobileMenu', function() {
      if (typeof dynamicPhoneNumber !== 'undefined') {
          $('#mega-menu-wrap-primary #mega-menu-primary > #mega-menu-item-3198').append('<a id="phone-icon" href="tel:' + dynamicPhoneNumber + '"><i aria-hidden="true" class="fas fa-phone-alt"></i></a>');
      }
  });
  $('ul#mega-menu-primary').on('mmm:hideMobileMenu', function() {
      $('a#phone-icon').remove();
  });
});